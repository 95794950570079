export const CharlotteZipCodes = [
	{ value: "28012", label: "28012" },
	{ value: "28016", label: "28016" },
	{ value: "28021", label: "28021" },
	{ value: "28025", label: "28025" },
	{ value: "28026", label: "28026" },
	{ value: "28027", label: "28027" },
	{ value: "28031", label: "28031" },
	{ value: "28032", label: "28032" },
	{ value: "28034", label: "28034" },
	{ value: "28036", label: "28036" },
	{ value: "28052", label: "28052" },
	{ value: "28054", label: "28054" },
	{ value: "28056", label: "28056" },
	{ value: "28075", label: "28075" },
	{ value: "28077", label: "28077" },
	{ value: "28078", label: "28078" },
	{ value: "28079", label: "28079" },
	{ value: "28081", label: "28081" },
	{ value: "28082", label: "28082" },
	{ value: "28083", label: "28083" },
	{ value: "28098", label: "28098" },
	{ value: "28101", label: "28101" },
	{ value: "28103", label: "28103" },
	{ value: "28104", label: "28104" },
	{ value: "28105", label: "28105" },
	{ value: "28106", label: "28106" },
	{ value: "28107", label: "28107" },
	{ value: "28108", label: "28108" },
	{ value: "28110", label: "28110" },
	{ value: "28111", label: "28111" },
	{ value: "28112", label: "28112" },
	{ value: "28120", label: "28120" },
	{ value: "28124", label: "28124" },
	{ value: "28134", label: "28134" },
	{ value: "28164", label: "28164" },
	{ value: "28173", label: "28173" },
	{ value: "28174", label: "28174" },
	{ value: "28202", label: "28202" },
	{ value: "28203", label: "28203" },
	{ value: "28204", label: "28204" },
	{ value: "28205", label: "28205" },
	{ value: "28206", label: "28206" },
	{ value: "28207", label: "28207" },
	{ value: "28208", label: "28208" },
	{ value: "28209", label: "28209" },
	{ value: "28210", label: "28210" },
	{ value: "28211", label: "28211" },
	{ value: "28212", label: "28212" },
	{ value: "28213", label: "28213" },
	{ value: "28214", label: "28214" },
	{ value: "28215", label: "28215" },
	{ value: "28216", label: "28216" },
	{ value: "28217", label: "28217" },
	{ value: "28226", label: "28226" },
	{ value: "28227", label: "28227" },
	{ value: "28244", label: "28244" },
	{ value: "28254", label: "28254" },
	{ value: "28262", label: "28262" },
	{ value: "28269", label: "28269" },
	{ value: "28270", label: "28270" },
	{ value: "28273", label: "28273" },
	{ value: "28277", label: "28277" },
	{ value: "28278", label: "28278" },
	{ value: "28280", label: "28280" },
	{ value: "28281", label: "28281" },
	{ value: "28282", label: "28282" },
	{ value: "28284", label: "28284" },
	{ value: "28285", label: "28285" },
	{ value: "28287", label: "28287" },
	{ value: "29058", label: "29058" },
	{ value: "29067", label: "29067" },
	{ value: "29708", label: "29708" },
	{ value: "29710", label: "29710" },
	{ value: "29715", label: "29715" },
	{ value: "29716", label: "29716" },
	{ value: "29717", label: "29717" },
	{ value: "29720", label: "29720" },
	{ value: "29721", label: "29721" },
	{ value: "29722", label: "29722" },
	{ value: "29726", label: "29726" },
	{ value: "29730", label: "29730" },
	{ value: "29731", label: "29731" },
	{ value: "29732", label: "29732" },
	{ value: "29733", label: "29733" },
	{ value: "29734", label: "29734" },
	{ value: "29742", label: "29742" },
	{ value: "29743", label: "29743" },
	{ value: "29744", label: "29744" },
	{ value: "29745", label: "29745" },
]
