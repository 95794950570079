import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import { Center, Heading, Stack, Spinner } from "@chakra-ui/react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import {
	collection,
	addDoc,
	query,
	where,
	getDocs,
	updateDoc,
	increment,
} from "firebase/firestore"
import { db, logUserEvent } from "../../firebase"
import config from "../../config.js"
import { UserContext } from "../../Contexts/UserContext.js"
import { JobRequestResponseForm } from "../../Components/JobRequestResponseForm.js"
import { JobResponseCreateServiceForm } from "../../Components/JobResponseCreateServiceForm.js"
import { checkVendorSubscriptionServiceLimit } from "../../Utilities.js"
import { analytic_events } from "../../analytics.js"

export const JobRequestResponse = () => {
	const { id } = useParams()
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()
	const location = useLocation()
	const [step, setStep] = useState(1)
	const [formError, setFormError] = useState(false)
	const [docRef, setDocRef] = useState(null)
	const [jobRequest, setJobRequest] = useState(null)
	const [service, setService] = useState(null)
	const [serviceRef, setServiceRef] = useState(null)
	const [paywallTriggered, setPaywallTriggered] = useState(false)
	const [pageLoading, setPageLoading] = useState(false)

	useEffect(() => {
		if (userPlus === "pending") {
			return
		}
		if (userPlus === null) {
			navigate("/login", { state: { from: location } })
			return
		}

		const getJobRequest = async () => {
			try {
				const q = query(collection(db, "jobRequests"), where("id", "==", id))
				const docSnap = await getDocs(q)

				if (!docSnap.empty) {
					setDocRef(docSnap.docs[0].ref)
					const docData = docSnap.docs[0].data()
					setJobRequest(docData)
				} else {
					console.error("No document found")
				}
			} catch (error) {
				console.error("Error getting document:", error)
			}
		}

		getJobRequest()
	}, [id, userPlus])

	useEffect(() => {
		setPageLoading(true)
		if (!jobRequest || userPlus === "pending") {
			return
		}
		if (jobRequest && userPlus !== null && userPlus !== "pending") {
			const getServiceDetails = async () => {
				const q = query(
					collection(db, "services"),
					where("userId", "==", userPlus.id),
					where("serviceType", "==", jobRequest.serviceType)
				)
				const services = await getDocs(q)
				const vendorUpgradeRequired =
					checkVendorSubscriptionServiceLimit(userPlus, 1) === false ||
					userPlus.activePlan === false ||
					userPlus.planName === null

				if (services.docs.length === 0 && !vendorUpgradeRequired) {
					setStep(1)
				} else {
					if (
						(services.docs.length === 0 && vendorUpgradeRequired) ||
						(services.docs.length > 0 &&
							(userPlus.activePlan === false || userPlus.planName === null))
					) {
						setPaywallTriggered(true)
						setStep(2)
					} else {
						const service = services.docs[0].data()
						setServiceRef(services.docs[0].ref)
						setStep(2)
						setService(service)
					}
				}
			}
			getServiceDetails()
			setPageLoading(false)
		}
	}, [jobRequest, userPlus])

	const verifyJobResponse = (
		availability,
		formData,
		alternativeTimes,
		quoteType
	) => {
		if (availability === null) {
			alert("Please select your availability.")
			setFormError(true)
			return false
		}
		if (availability) {
			if (
				!formData.serviceCharge &&
				(!formData.minQuote || !formData.maxQuote)
			) {
				alert("Please add a quote.")
				setFormError(true)
				return false
			}
			if (formData.serviceCharge && !formData.serviceChargeCost) {
				alert("Please add a service charge cost.")
				setFormError(true)
				return false
			}
		} else {
			setFormError(false)
			return true
		}
	}

	const onSubmit = async (e, availability, formData, alternativeTimes) => {
		e.preventDefault()
		if (verifyJobResponse(availability, formData, alternativeTimes) === false) {
			return
		}
		await addDoc(collection(db, "jobRequestResponses"), {
			id: crypto.randomUUID(),
			userId: userPlus.id,
			vendorContactEmail: service.contactEmail
				? service.contactEmail
				: userPlus.email,
			vendorName: service.name,
			requestId: jobRequest.id,
			requestEmail: jobRequest.userEmail,
			available: availability,
			_createdAt: new Date(),
			_updatedAt: new Date(),
			_createdBy: userPlus.id,
			_updatedBy: userPlus.id,
			...formData,
			serviceChargeCost: formData.serviceCharge
				? formData.serviceChargeCost
				: null,
			minQuote: formData.serviceCharge ? null : formData.minQuote,
			maxQuote: formData.serviceCharge ? null : formData.maxQuote,
			...(userPlus.partner && { partner: userPlus.partner }),
		})
		await updateDoc(docRef, { vendorsAvailable: increment(1) })
		sendJobRequestResponse(formData, availability, alternativeTimes)
		await updateDoc(serviceRef, { jobResponseCount: increment(1) })
		await logUserEvent(analytic_events.JOB_RESPONSE_SUBMITTED, userPlus.id, {
			vendorId: userPlus.id,
			jobRequestId: jobRequest.id,
		})
		navigate("/job-requests")
	}

	if (userPlus === null) {
		navigate("/login")
	}

	const sendJobRequestResponse = async (
		formData,
		availability,
		alternativeTimes
	) => {
		const userEmail = userPlus.email
		const userName = userPlus.name
		try {
			await axios.post(`${config.baseURL}/api/job-request-response`, {
				formData,
				availability,
				alternativeTimes,
				userEmail,
				userName,
				jobRequest,
			})
		} catch (error) {
			console.error("Error sending email:", error)
		}
	}

	const nextStep = async (serviceData, stateSelection) => {
		const locationMissing =
			(serviceData.selectLocations?.length === 0 &&
				serviceData.zipCodesServiced?.length === 0) ||
			stateSelection.length === 0
		if (locationMissing) {
			alert("Please select at least one location.")
			setFormError(true)
			return
		}
		const id = crypto.randomUUID()
		await addDoc(collection(db, "services"), {
			id,
			userId: userPlus.id,
			_createdAt: new Date(),
			_updatedAt: new Date(),
			_createdBy: userPlus.id,
			_updatedBy: userPlus.id,
			...serviceData,
			stateLocation: stateSelection,
			subscriptionStatusActive: userPlus.activePlan,
			serviceType: jobRequest.serviceType,
			contactEmail:
				serviceData.contactEmail === "" ? null : serviceData.contactEmail,
		}).then((docRef) => {
			setServiceRef(docRef)
		})
		await addDoc(collection(db, "vendorServiceMap"), {
			_createdAt: new Date(),
			vendorId: userPlus.id,
			serviceId: id,
		})
		await updateDoc(userPlus.userDocRef, { numberOfServices: increment(1) })
		setService({
			...serviceData,
		})
		setFormError(false)
		setStep(2)
	}

	if (pageLoading) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
					<Heading size="lg" fontWeight="bold" mt="30px">
						{" "}
						Loading Job Request{" "}
					</Heading>
				</Stack>
			</Center>
		)
	}

	return (
		<>
			{step === 1 && userPlus && userPlus !== "pending" && jobRequest && (
				<JobResponseCreateServiceForm
					jobRequest={jobRequest}
					nextStep={nextStep}
					formError={formError}
				/>
			)}
			{step === 2 && (
				<JobRequestResponseForm
					jobRequest={jobRequest}
					service={service}
					formError={formError}
					setFormError={setFormError}
					paywallTriggered={paywallTriggered}
					onSubmit={onSubmit}
				/>
			)}
		</>
	)
}
