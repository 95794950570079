import { useState, useContext } from "react"
import {
	Box,
	Card,
	CardBody,
	Center,
	Text,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Button,
	Heading,
} from "@chakra-ui/react"
import { logUserEvent } from "../firebase"
import { analytic_events } from "../analytics"
import { UserContext } from "../Contexts/UserContext"

export const AgentPayWallModal = ({
	isOpen,
	setPaywallTriggered,
	location,
}) => {
	const { userPlus } = useContext(UserContext)
	const [selectedCard, setSelectedCard] = useState(null)
	const onClose = async () => {
		await logUserEvent(analytic_events.AGENT_CLOSE_PAYWALL_MODAL, userPlus.id, {
			location,
		})
		setPaywallTriggered(false)
	}

	const handleCardSelection = async (selection) => {
		await logUserEvent(
			analytic_events.AGENT_PAYWALL_CARD_SELECTION,
			userPlus.id,
			{
				selection: selection,
				location: location,
			}
		)
		setSelectedCard(selection)
	}

	const handleContinue = async () => {
		await logUserEvent(analytic_events.AGENT_STRIPE_CTA_CLICK, userPlus.id, {
			selection: selectedCard,
			location,
		})
	}

	const prodStripeUrls = [
		{ value: "Base", url: "https://buy.stripe.com/3cs6rpg451GgecgcMP" },
		{ value: "Growing", url: "https://buy.stripe.com/eVa6rpf01et2c48aEI" },
		{ value: "Enterprise", url: "mailto:contact@quiploteams.com" },
	]

	const testStripeUrls = [
		{ value: "Base", url: "https://buy.stripe.com/test_5kA28uereaBt1PO4gg" },
		{ value: "Growing", url: "https://buy.stripe.com/test_9AQ6oK5UIaBteCAfZ1" },
		{ value: "Enterprise", url: "mailto:contact@quiploteams.com" },
	]

	const stripeUrls =
		process.env.NODE_ENV === "development" ? testStripeUrls : prodStripeUrls

	return (
		<Modal onClose={onClose} size="full" isOpen={isOpen}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Center mt="30px">
						<Image src="/assets/logo_with_name.svg" />
					</Center>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Heading size="md" fontWeight="semibold" mb="10px" align="center">
						Unlock premium features to your home services exchange
					</Heading>
					<Heading size="sm" mb="20px" align="center">
						Choose the best plan to continue using Quiplo for your business
					</Heading>
					<Box
						display={{ lg: "flex" }}
						justifyContent="center"
						alignItems="stretch"
					>
						<Box onClick={() => handleCardSelection("Base")}>
							<Card
								border={
									selectedCard === "Base"
										? "1px solid #EE5257"
										: "1px solid #E2E8F0"
								}
								mb="20px"
								h="100%"
								maxW={{ lg: "318px" }}
								mr={{ lg: "25px" }}
							>
								<CardBody>
									<Box>
										<Heading fontWeight="bold">BASE</Heading>
										<Box display="flex">
											<Text fontWeight="bold" fontSize="xl">
												$0
											</Text>
											<Text fontSize="lg">/mo</Text>
										</Box>
									</Box>
									<Text color="coolGray.600" mt="5px" mb="10px">
										Best for agents to try out Quiplo with a few vendors and job
										requests.
									</Text>
									<Box display="flex">
										<Image src="/assets/check.svg" />
										<Text ml="10px">5 job requests total for FREE</Text>
									</Box>
								</CardBody>
							</Card>
						</Box>
						<Box onClick={() => handleCardSelection("Growing")}>
							<Card
								border={
									selectedCard === "Growing"
										? "1px solid #EE5257"
										: "1px solid #E2E8F0"
								}
								mb="20px"
								h="100%"
								maxW={{ lg: "318px" }}
								mr={{ lg: "25px" }}
							>
								<CardBody>
									<Box>
										<Heading fontWeight="bold">GROWING</Heading>
										<Box display="flex">
											<Text fontWeight="bold" fontSize="xl">
												$10
											</Text>
											<Text fontSize="lg">/mo/agent</Text>
										</Box>
									</Box>
									<Text color="coolGray.600" mt="5px" mb="10px">
										Best for agents who work with a few vendors and clients.
									</Text>
									<Box display="flex" mb="5px">
										<Image src="/assets/check.svg" />
										<Text ml="10px">UNLIMITED job requests</Text>
									</Box>
									<Box display="flex">
										<Image src="/assets/check.svg" />
										<Text ml="10px">Invite Clients</Text>
									</Box>
								</CardBody>
							</Card>
						</Box>
						<Box onClick={() => handleCardSelection("Enterprise")}>
							<Card
								border={
									selectedCard === "Enterprise"
										? "1px solid #EE5257"
										: "1px solid #E2E8F0"
								}
								mb="20px"
								h="100%"
								maxW={{ lg: "318px" }}
							>
								<CardBody>
									<Box>
										<Heading fontWeight="bold">ENTERPRISE</Heading>
										<Box display="flex">
											<Text>
												Contact us to learn about pricing and team features
											</Text>
										</Box>
									</Box>
									<Text color="coolGray.600" mt="5px" mb="10px">
										Best for large teams and brokerages who work with many
										vendors and clients.
									</Text>
								</CardBody>
							</Card>
						</Box>
					</Box>
					<Center mt="20px">
						<Box m="auto" maxW="325px" width="100%">
							<Text align="center">Cancel subscription anytime.</Text>
							<a
								href={
									stripeUrls.find((item) => item.value === selectedCard)?.url
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Button
									isDisabled={!selectedCard}
									onClick={handleContinue}
									color="white"
									background="borderColor"
									mt="10px"
									width="100%"
									mb="40px"
								>
									CONTINUE
								</Button>
							</a>
						</Box>
					</Center>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
