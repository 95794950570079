import React, { useEffect, useCallback, useState, useContext } from "react"
import { useParams, NavLink, useNavigate, useLocation } from "react-router-dom"
import {
	Box,
	Heading,
	HStack,
	IconButton,
	Link,
	Tag,
	TagLabel,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
} from "@chakra-ui/react"
import { EditIcon, ArrowBackIcon } from "@chakra-ui/icons"
import { collection, query, where, getDocs } from "firebase/firestore"
import { db } from "../../firebase"

import { dateToFormat } from "../../Utilities"
import { UserContext } from "../../Contexts/UserContext"

import { ClientInsightInformationBox } from "../../Components/ClientInsightInformationBox.js"
import { JobRequestsMobileCard } from "../../Components/JobRequestsMobileCard.js"

export const ClientInsight = () => {
	const location = useLocation()
	const { client } = location.state || {}
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()
	const { id } = useParams()
	const [jobRequests, setJobRequests] = useState(null)

	const fetchData = useCallback(async () => {
		if (userPlus === "pending" || userPlus === null) return

		try {
			const jobRequestQ = query(
				collection(db, "jobRequests"),
				where("userId", "==", id)
			)
			const jobRequestSnap = await getDocs(jobRequestQ)

			if (jobRequestSnap.empty) {
				console.error("Job request not found")
				return
			}

			const jobRequestData = jobRequestSnap.docs.map((doc) => doc.data())

			setJobRequests(jobRequestData)
		} catch (error) {
			console.error("Error in getting client insights:", error)
		}
	}, [id, userPlus])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	return (
		<>
			<Box
				ml={{ base: "15px", lg: "93px" }}
				mr={{ base: "15px", lg: "93px" }}
				mt="30px"
			>
				<Heading size="lg" fontWeight="bold">
					Client Activity
				</Heading>
				<HStack my="15px" gap={0}>
					<IconButton
						aria-label="Return to Client Insights"
						size="md"
						bgColor="background"
						icon={<ArrowBackIcon />}
						onClick={() => navigate("/insights")}
						_hover={{ color: "borderColor" }}
					/>
					<Link as={NavLink} variant="secondary" to="/insights">
						All Client Inisghts
					</Link>
				</HStack>
				{client && <ClientInsightInformationBox client={client} />}
				<Box
					display={["flex", "flex", "none", "none"]}
					mt="20px"
					flexDirection="column"
				>
					{jobRequests?.length > 0 &&
						jobRequests.map((jobRequest, index) => {
							return (
								<JobRequestsMobileCard
									jobRequest={jobRequest}
									index={index}
									key={index}
								/>
							)
						})}
				</Box>
				<Box display={["none", "none", "flex", "flex"]} mt="30px">
					<Table variant="simple" mt="40px">
						<Thead>
							<Tr>
								<Th>Location</Th>
								<Th>Service Type</Th>
								<Th>Request Date</Th>
								<Th>Status</Th>
								<Th>Details</Th>
							</Tr>
						</Thead>
						<Tbody>
							{jobRequests?.length > 0 &&
								jobRequests?.map((jobRequest) => {
									return (
										<Tr key={jobRequest.id}>
											<Td>{jobRequest.locationAddress}</Td>
											<Td>{jobRequest.serviceType}</Td>
											<Td>{dateToFormat(jobRequest._createdAt)}</Td>
											<Td>
												{jobRequest.vendorsAvailable &&
													jobRequest.selectedResponseId && (
														<Tag
															borderRadius="full"
															variant="solid"
															bgColor="green"
															mr="5px"
															mb="5px"
														>
															<TagLabel>Scheduled</TagLabel>
														</Tag>
													)}

												{jobRequest.vendorsAvailable &&
													!jobRequest.selectedResponseId && (
														<Tag
															borderRadius="full"
															w="260px"
															variant="solid"
															bgColor="orange"
															mr="5px"
															mb="5px"
														>
															<TagLabel>In Progress</TagLabel>
														</Tag>
													)}
												{!jobRequest.vendorsAvailable && (
													<Tag
														borderRadius="full"
														variant="solid"
														bgColor="
														#gray.500"
														mr="5px"
														mb="5px"
													>
														<TagLabel>Awaiting Repsonses</TagLabel>
													</Tag>
												)}
											</Td>
											<Td>
												<IconButton
													key={jobRequest.id}
													as={NavLink}
													bgColor="background"
													to={{
														pathname: `/job-requests/responses/${jobRequest.id}`,
														state: { from: location.pathname },
													}}
													aria-label="View Job Request Details"
													icon={<EditIcon />}
												/>
											</Td>
										</Tr>
									)
								})}
						</Tbody>
					</Table>
				</Box>
			</Box>
		</>
	)
}
