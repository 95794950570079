// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getPerformance } from "firebase/performance"
import { getAnalytics, logEvent } from "firebase/analytics"

import { analytic_events } from "./analytics"

import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
} from "firebase/auth"
import {
	getFirestore,
	query,
	getDocs,
	collection,
	where,
	addDoc,
	updateDoc,
} from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { teamIdDefaultIds } from "./Options/DefaultTeamIds.js"

const firebaseConfigProd = {
	apiKey: "AIzaSyBDRXhDQs5rRvfOyXNwLwSkgLYj9Fw4E_o",
	authDomain: "relay-57861.firebaseapp.com",
	projectId: "relay-57861",
	storageBucket: "relay-57861.appspot.com",
	messagingSenderId: "746961660083",
	appId: "1:746961660083:web:a6741c3c93d85a4ca528c6",
	measurementId: "G-Q5E90KLDQJ",
}

const firebaseConfigDev = {
	apiKey: "AIzaSyAZ5GSJrhtr8eag6NVy1sBo4j4Jdn7EcUc",
	authDomain: "quiplo-dev.firebaseapp.com",
	projectId: "quiplo-dev",
	storageBucket: "quiplo-dev.appspot.com",
	messagingSenderId: "340209341520",
	appId: "1:340209341520:web:23e81b1a37b1e01dad2d55",
	measurementId: "G-VLMZLMFPPD",
}

const firebaseConfig =
	process.env.NODE_ENV === "production" ? firebaseConfigProd : firebaseConfigDev

const app = initializeApp(firebaseConfig)
const perf = getPerformance(app)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

const googleProvider = new GoogleAuthProvider()
const signInWithGoogle = async (formData, register, id, partner) => {
	const { name, userType, phoneNumber } = formData
	try {
		const res = await signInWithPopup(auth, googleProvider)
		const user = res.user
		if (register) {
			let invitingUserTeam
			let invitingUserType
			if (id && id.length > 10) {
				const q1 = query(collection(db, "users"), where("id", "==", id))
				const docs = await getDocs(q1)
				invitingUserTeam = docs.docs[0].data().teamId
				invitingUserType = docs.docs[0].data().userType
			}
			let team
			if (id !== "HPV") {
				team = teamIdDefaultIds.find((team) => team.teamId === id) || null
			}
			const q = query(collection(db, "users"), where("email", "==", user.email))
			const docs = await getDocs(q)
			let userId
			userId = docs.docs[0] ? docs.docs[0].data().id : null
			if (docs.docs.length === 0) {
				userId = crypto.randomUUID()
				await addDoc(collection(db, "users"), {
					id: userId,
					_createdAt: new Date(),
					_updatedAt: new Date(),
					_lastLogin: new Date(),
					uid: user.uid,
					name,
					phoneNumber,
					userType,
					...(userType === "vendor" && { numberOfServices: 0 }),
					...(userType !== "vendor" && {
						teamId: team
							? team.teamId
							: invitingUserTeam
							? invitingUserTeam
							: null,
					}),
					...(id === "HPV" && { partner: id }),
					...(partner && { partner }),
					source: id ? "campaign" : null,
					...(userType !== "vendor" && { jobRequestsMade: 0 }),
					authProvider: "google",
					invitedBy: team ? team.defaultId : id || null,
					email: user.email,
				})
			} else {
				await updateDoc(docs.docs[0].ref, {
					_updatedAt: new Date(),
					_updatedBy: docs.docs[0].data().id,
					_lastLogin: new Date(),
					uid: user.uid,
					name,
					authProvider: "google",
					email: user.email,
					phoneNumber,
				})
			}
			if (userType === "client" && id) {
				await addDoc(collection(db, "agentClient"), {
					agentId: team ? team.defaultId : id || null,
					clientId: userId,
					teamId: team
						? team.teamId
						: invitingUserTeam
						? invitingUserTeam
						: null,
					...(id === "HPV" && { partner: id }),
					...(partner && { partner }),
				})
			}

			if (userType === "vendor" && id) {
				await addDoc(collection(db, "agentVendor"), {
					agentId: team ? team.defaultId : id || null,
					vendorId: userId,
					teamId: team ? team.teamId : null,
					...(id === "HPV" && { partner: id }),
					...(partner && { partner }),
				})
			}
			if (userType === "agent" && id && invitingUserType === "vendor") {
				await addDoc(collection(db, "agentVendor"), {
					_createdAt: new Date(),
					agentId: userId,
					vendorId: id,
					teamId: null,
					...(id === "HPV" && { partner: id }),
					...(partner && { partner }),
				})
			}
		}
		const usersCollection = collection(db, "users")
		const q = query(usersCollection, where("uid", "==", user.uid))
		const querySnapshot = await getDocs(q)

		if (!querySnapshot.empty) {
			const userDocRef = querySnapshot.docs[0].ref
			await updateDoc(userDocRef, {
				_lastLogin: new Date(),
			})
		} else {
			console.error("No user document found with the matching uid!")
		}

		await logUserEvent(analytic_events.USER_CREATION, user.uid, {
			authProvider: "google",
			userType: userType,
			campaign: id ? id : null,
		})
	} catch (err) {
		console.error(err)
		alert(err.message)
	}
	return true
}

const logUserEvent = async (eventName, userId, details) => {
	try {
		await logEvent(analytics, eventName, {
			userId: userId,
			...details,
		})
	} catch (err) {
		console.error("Error logging event: ", eventName)
	}
}

const logInWithEmailAndPassword = async (email, password) => {
	try {
		const userCredential = await signInWithEmailAndPassword(
			auth,
			email,
			password
		)
		const user = userCredential.user

		const usersCollection = collection(db, "users")
		const q = query(usersCollection, where("uid", "==", user.uid))
		const querySnapshot = await getDocs(q)

		if (!querySnapshot.empty) {
			const userDocRef = querySnapshot.docs[0].ref
			await updateDoc(userDocRef, {
				_lastLogin: new Date(),
			})
		} else {
			console.error("No user document found with the matching uid!")
		}
	} catch (err) {
		console.error(err)
		alert(err.message)
	}
}

const registerWithEmailAndPassword = async (formData, id, partner) => {
	const { password, name, userType, phoneNumber } = formData
	let { email } = formData
	let isUserAlreadyAssociated = false
	email = email.toLowerCase()
	try {
		const res = await createUserWithEmailAndPassword(auth, email, password)
		const user = res.user
		let invitingUserTeam
		let invitingUserType
		if (id && id.length > 10) {
			const q1 = query(collection(db, "users"), where("id", "==", id))
			const docs = await getDocs(q1)
			invitingUserTeam = docs.docs[0]?.data().teamId
			invitingUserType = docs.docs[0]?.data().userType
		}
		let team
		if (id !== "HPV") {
			team = teamIdDefaultIds.find((team) => team.teamId === id) || null
		}
		const q1 = query(collection(db, "users"), where("email", "==", email))
		const q2 = query(
			collection(db, "users"),
			where("phoneNumber", "==", phoneNumber)
		)
		const doc1 = await getDocs(q1)
		const doc2 = await getDocs(q2)
		let userId
		let docRef
		userId = doc1.docs[0]
			? doc1.docs[0].data().id
			: doc2.docs[0]
			? doc2.docs[0].data().id
			: null

		docRef = doc1.docs[0]
			? doc1.docs[0].ref
			: doc2.docs[0]
			? doc2.docs[0].ref
			: null
		if (!userId) {
			userId = crypto.randomUUID()
			await addDoc(collection(db, "users"), {
				id: userId,
				_createdAt: new Date(),
				_updatedAt: new Date(),
				_lastLogin: new Date(),
				uid: user.uid,
				name,
				phoneNumber,
				userType,
				...(userType === "vendor" && { numberOfServices: 0 }),
				...(userType !== "vendor" && {
					teamId: team
						? team.teamId
						: invitingUserTeam
						? invitingUserTeam
						: null,
				}),
				...(id === "HPV" && { partner: id }),
				...(partner && { partner }),
				source: id ? "campaign" : null,
				...(userType !== "vendor" && { jobRequestsMade: 0 }),
				authProvider: "local",
				invitedBy: team ? team.defaultId : id || null,
				email,
			})
		} else {
			await updateDoc(docRef, {
				_updatedAt: new Date(),
				uid: user.uid,
				name,
				phoneNumber,
				authProvider: "local",
				email,
			})

			if (userType === "client") {
				const q1 = query(
					collection(db, "agentClient"),
					where("clientId", "==", userId)
				)
				const docs = await getDocs(q1)

				if (docs.docs.length !== 0) {
					isUserAlreadyAssociated = true
				}
			}
		}

		if (userType === "client" && id && !isUserAlreadyAssociated) {
			await addDoc(collection(db, "agentClient"), {
				agentId: team ? team.defaultId : id || null,
				clientId: userId,
				teamId: team ? team.teamId : invitingUserTeam,
				...(id === "HPV" && { partner: id }),
				...(partner && { partner }),
			})
		}
		if (userType === "vendor" && id) {
			await addDoc(collection(db, "agentVendor"), {
				agentId: team ? team.defaultId : id || null,
				vendorId: userId,
				teamId: team ? team.teamId : null,
				...(id === "HPV" && { partner: id }),
				...(partner && { partner }),
			})
		}
		if (userType === "agent" && id && invitingUserType === "vendor") {
			await addDoc(collection(db, "agentVendor"), {
				agentId: userId,
				vendorId: id,
				teamId: null,
				...(id === "HPV" && { partner: id }),
				...(partner && { partner }),
			})
		}
		await logUserEvent(analytic_events.USER_REGISTRATION, user.uid, {
			authProvider: "local",
			email: email,
			userType: userType,
			campaign: id ? id : null,
		})
	} catch (err) {
		console.error(err)
		alert(err.message)
	}
}

const sendPasswordReset = async (email) => {
	try {
		await sendPasswordResetEmail(auth, email)
		alert("Password reset link sent!")
	} catch (err) {
		console.error(err)
		alert(err.message)
	}
}

const logout = () => {
	signOut(auth)
}

export {
	auth,
	perf,
	db,
	logInWithEmailAndPassword,
	logout,
	logUserEvent,
	registerWithEmailAndPassword,
	sendPasswordReset,
	signInWithGoogle,
	storage,
}
