import { Container, Card, Heading, Image, Text } from "@chakra-ui/react"

export const AgentProfileInfo = ({ agentInfo }) => {
	return (
		<>
			<Card mt="20px" display="flex" flexDirection="row" maxW="xl" p="20px">
				<Image
					src={process.env.PUBLIC_URL + "/assets/clipboard_icon.svg"}
					alt="Task Icon"
					w="65px"
					h="65px"
				/>
				<Container>
					<Heading size="md" fontWeight="bold" pb="5px">
						Welcome to my vendor portal!
					</Heading>
					<Text>
						Welcome to my vendor portal! Find and connect with trusted vendors
						for your home projects. Submit a job request today to make
						homeownership simpler.
					</Text>
				</Container>
			</Card>
			<Card mt="20px" display="flex" flexDirection="row" maxW="xl" p="20px">
				<Image
					src={process.env.PUBLIC_URL + "/assets/house_icon.svg"}
					alt="House Icon"
					w="65px"
					h="65px"
				/>
				<Container>
					<Heading size="md" fontWeight="bold" pb="5px">
						Explore my trusted vendor network!
					</Heading>
					<Text>
						Access skilled professionals and submit job requests effortlessly.
						Let’s take the hassle out of home projects!
					</Text>
				</Container>
			</Card>
		</>
	)
}
