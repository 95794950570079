import React, { useEffect, useCallback, useState, useContext } from "react"
import axios from "axios"
import { useParams, NavLink, useNavigate, useLocation } from "react-router-dom"
import {
	Box,
	Heading,
	HStack,
	IconButton,
	Link,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Tag,
	TagLabel,
	Text,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	useDisclosure,
} from "@chakra-ui/react"
import { UpDownIcon, ArrowBackIcon } from "@chakra-ui/icons"
import {
	collection,
	query,
	where,
	getDocs,
	updateDoc,
} from "firebase/firestore"
import { db, logUserEvent } from "../../firebase"
import { JobRequestResponseMobileCard } from "../../Components/JobRequestResponseMobileCard"
import { JobRequestResponseAwaitingResponseMobileCard } from "../../Components/JobRequestResponseAwaitingResponseMobileCard"
import { JobDetailsInformationBox } from "../../Components/JobDetailsInformationBox"
import { JobRequestScheduleModal } from "../../Components/JobRequestScheduleModal"
import { dateToFormat, formatPhoneNumber, convertToAMPM } from "../../Utilities"
import { UserContext } from "../../Contexts/UserContext"
import { analytic_events } from "../../analytics"
import config from "../../config.js"
import "../../date-picker.css"

export const ClientAgentJobRequestResponsesListView = () => {
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()
	const location = useLocation()
	const { id } = useParams()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [linkText, setLinkText] = useState("")
	const [jobRequest, setJobRequest] = useState(null)
	const [awaitingVendors, setAwaitingVendors] = useState([])
	const [isScheduling, setIsScheduling] = useState(false)
	const [jobRequestResponses, setJobRequestResponses] = useState([])
	const [showDatePicker, setShowDatePicker] = useState(false)
	const [menuOpenStates, setMenuOpenStates] = useState(
		new Array(jobRequestResponses.length).fill(false)
	)

	useEffect(() => {
		setMenuOpenStates(new Array(jobRequestResponses.length).fill(false))
	}, [jobRequestResponses])

	const toggleMenu = (index) => {
		setMenuOpenStates((prevStates) =>
			prevStates.map((state, i) => (i === index ? !state : false))
		)
	}

	const fetchData = useCallback(async () => {
		if (userPlus === "pending" || isScheduling || userPlus === null) return

		try {
			const jobRequestQ = query(
				collection(db, "jobRequests"),
				where("id", "==", id)
			)
			const jobRequestSnap = await getDocs(jobRequestQ)

			if (jobRequestSnap.empty) {
				console.error("Job request not found")
				return
			}

			const jobRequestData = jobRequestSnap.docs[0].data()
			const vendorIds = jobRequestData.vendorIds || []

			const vendorDataSnap = await getDocs(
				query(collection(db, "users"), where("id", "in", vendorIds))
			)
			const vendorData = vendorDataSnap.docs.map((doc) => doc.data())

			setJobRequest(jobRequestData)

			const jobRequestResponsesQ = query(
				collection(db, "jobRequestResponses"),
				where("requestId", "==", id)
			)
			const jobRequestResponsesSnap = await getDocs(jobRequestResponsesQ)
			const jobRequestResponsesData = jobRequestResponsesSnap.docs.map(
				(doc) => ({
					id: doc.id,
					...doc.data(),
				})
			)

			setJobRequestResponses(jobRequestResponsesData)
			if (jobRequestResponsesData.length > 0) {
				const awaitingVendors = vendorData.filter(
					(vendor) =>
						!jobRequestResponsesData.some(
							(jobRequest) => jobRequest.userId === vendor.id
						)
				)

				setAwaitingVendors(awaitingVendors)
			} else {
				setAwaitingVendors(vendorData)
			}
		} catch (error) {
			console.error("Error fetching data:", error)
		}
	}, [id, isScheduling, userPlus])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	useEffect(() => {
		const previousUrl = location.state?.from || ""
		if (previousUrl.includes("/insights")) {
			setLinkText("Return to Client Insights")
		} else {
			setLinkText("All Job Requests")
		}
	}, [location])

	const handleCloseModal = () => {
		setMenuOpenStates(new Array(jobRequestResponses.length).fill(false))
		onClose()
	}

	const handleScheduling = async (e, jobRequestResponse) => {
		if (isScheduling) return

		setIsScheduling(true)
		const q = query(collection(db, "jobRequests"), where("id", "==", id))
		const docSnap = await getDocs(q)
		if (docSnap.docs[0].data().selectedResponseId) {
			await updateDoc(docSnap.docs[0].ref, {
				selectedResponseId: null,
				scheduleDate: null,
				scheduleStartTime: null,
				scheduleEndTime: null,
			})

			await logUserEvent(
				analytic_events.AGENT_CLIENT_UNSCHEDULED,
				userPlus.id,
				{
					unscheduledResponsesId: docSnap.docs[0].data().selectedResponseId,
					userId: userPlus.id,
				}
			)
		} else {
			await updateDoc(docSnap.docs[0].ref, {
				selectedResponseId: jobRequestResponse.id,
				scheduleDate: e.date,
				scheduleStartTime: e.startTime,
				scheduleEndTime: e.endTime,
			})
			await logUserEvent(analytic_events.AGENT_CLIENT_SCHEDULED, userPlus.id, {
				scheduledResponsesId: jobRequestResponse.id,
				userId: userPlus.id,
			})
			sendSchedulingEmail(jobRequestResponse, e)
		}
		setShowDatePicker(false)
		setIsScheduling(false)
		onClose()
		setMenuOpenStates(new Array(jobRequestResponses.length).fill(false))
	}

	const sendSchedulingEmail = async (jobRequestResponse, e) => {
		const userEmail = userPlus.email
		const userName = userPlus.name
		const scheduleDateTimes = e
		try {
			await axios.post(`${config.baseURL}/api/job-request-scheduling`, {
				jobRequestResponse,
				jobRequest,
				scheduleDateTimes,
				userEmail,
				userName,
			})
		} catch (error) {
			console.error("Error sending email:", error)
		}
	}
	return (
		<>
			<Box
				ml={{ base: "15px", lg: "93px" }}
				mr={{ base: "15px", lg: "93px" }}
				mt="30px"
			>
				<Heading size="lg" fontWeight="bold">
					Job Request Responses
				</Heading>
				<HStack my="15px" gap={0}>
					<IconButton
						aria-label="Return"
						size="md"
						bgColor="background"
						icon={<ArrowBackIcon />}
						onClick={() => navigate(-1)}
						_hover={{ color: "borderColor" }}
					/>
					<Link as={NavLink} variant="secondary" onClick={() => navigate(-1)}>
						{linkText}
					</Link>
				</HStack>
				<JobDetailsInformationBox jobRequest={jobRequest} />
				<Box
					display={["flex", "flex", "none", "none"]}
					mt="20px"
					flexDirection="column"
				>
					{jobRequestResponses.length > 0 &&
						jobRequestResponses.map((jobRequestResponse, index) => {
							return (
								<JobRequestResponseMobileCard
									key={index}
									jobRequestResponse={jobRequestResponse}
									jobRequest={jobRequest}
									handleScheduling={handleScheduling}
									selectedResponseId={jobRequest?.selectedResponseId}
									showDatePicker={showDatePicker}
									setShowDatePicker={setShowDatePicker}
								/>
							)
						})}
					{awaitingVendors.length > 0 &&
						awaitingVendors.map((vendor, index) => {
							return (
								<JobRequestResponseAwaitingResponseMobileCard
									vendor={vendor}
									key={index}
								/>
							)
						})}
				</Box>
				<Box display={["none", "none", "flex", "flex"]} mt="30px">
					<Table variant="simple" mt="40px">
						<Thead>
							<Tr>
								<Th>Vendor</Th>
								<Th>Job Estimate</Th>
								<Th>Alternative Dates & Times</Th>
								<Th>Vendor Status</Th>
							</Tr>
						</Thead>
						<Tbody>
							{jobRequestResponses.length > 0 &&
								jobRequestResponses.map((jobRequestResponse, index) => {
									return (
										<React.Fragment key={index}>
											<Tr key={index}>
												<Td>{jobRequestResponse.vendorName}</Td>
												<Td>
													{jobRequestResponse.minQuote &&
														jobRequestResponse.maxQuote && (
															<Text>
																${jobRequestResponse.minQuote}-$
																{jobRequestResponse.maxQuote}
															</Text>
														)}
													{jobRequestResponse.serviceCharge && (
														<Box display="flex">
															<Text>
																${jobRequestResponse.serviceChargeCost}
															</Text>
															<Tag
																ml="10px"
																width="125px"
																borderRadius="full"
																variant="solid"
																bgColor="blue.300"
																color="blue.800"
															>
																<TagLabel>Service Charge</TagLabel>
															</Tag>
														</Box>
													)}
												</Td>
												<Td>
													{jobRequestResponse.servicePreferredDates.map(
														(entry, index) => (
															<HStack gap={5} mb="5px" key={index}>
																<Text key={index}>
																	{dateToFormat(entry.date)}{" "}
																	{convertToAMPM(entry.startTime)} -{" "}
																	{convertToAMPM(entry.endTime)}
																</Text>
															</HStack>
														)
													)}
												</Td>
												<Td>
													{jobRequestResponse.available &&
														!jobRequest?.selectedResponseId && (
															<Tag
																borderRadius="full"
																variant="solid"
																bgColor="green"
																mr="5px"
																mb="5px"
															>
																<TagLabel>Available</TagLabel>
															</Tag>
														)}
													{!jobRequestResponse.available && (
														<Tag
															borderRadius="full"
															variant="solid"
															bgColor="borderColor"
															mr="5px"
															mb="5px"
														>
															<TagLabel>Not Available</TagLabel>
														</Tag>
													)}{" "}
													{jobRequest?.selectedResponseId ===
														jobRequestResponse.id && (
														<Tag
															borderRadius="full"
															w="260px"
															variant="solid"
															bgColor="green"
															mr="5px"
															mb="5px"
														>
															<TagLabel>
																Request to schedule -{" "}
																{dateToFormat(jobRequest.scheduleDate)}
															</TagLabel>
														</Tag>
													)}
												</Td>
												<Td>
													{" "}
													<Menu
														key={index}
														closeOnSelect={false}
														isOpen={menuOpenStates[index]}
													>
														<MenuButton
															as={IconButton}
															icon={<UpDownIcon />}
															onClick={() => toggleMenu(index)}
														/>
														<MenuList>
															{jobRequest.selectedResponseId && (
																<MenuItem
																	onClick={() =>
																		handleScheduling(jobRequestResponse)
																	}
																>
																	Undo scheudle
																</MenuItem>
															)}
															{!jobRequest.selectedResponseId && (
																<MenuItem onClick={() => onOpen()}>
																	Schedule
																</MenuItem>
															)}
														</MenuList>
													</Menu>
												</Td>
											</Tr>

											{menuOpenStates[index] === true && (
												<JobRequestScheduleModal
													handleScheduling={handleScheduling}
													jobRequest={jobRequest}
													jobRequestResponse={jobRequestResponse}
													setMenuOpenStates={setMenuOpenStates}
													isOpen={isOpen}
													onClose={handleCloseModal}
												/>
											)}
										</React.Fragment>
									)
								})}
							{awaitingVendors.length > 0 &&
								awaitingVendors.map((vendor) => {
									return (
										<Tr key={vendor.id}>
											<Td>
												{vendor.name
													? vendor.name
													: vendor.email
													? vendor.email
													: formatPhoneNumber(vendor.phoneNumber)}
											</Td>
											<Td />
											<Td />
											<Td>
												{" "}
												<Tag
													borderRadius="full"
													variant="solid"
													bgColor="
													#gray.500"
													mr="5px"
													mb="5px"
												>
													<TagLabel>Awaiting Repsonse</TagLabel>
												</Tag>
											</Td>
										</Tr>
									)
								})}
						</Tbody>
					</Table>
				</Box>
			</Box>
		</>
	)
}
