import { Card, Image, Container, Heading, Text } from "@chakra-ui/react"

export const ReferralCard = ({ isHPV }) => {
	return (
		<Card mt="20px" display="flex" flexDirection="row" maxW="xl" p="20px">
			<Image
				src={
					isHPV
						? process.env.PUBLIC_URL + "/assets/hpv_recommend_icon.svg"
						: process.env.PUBLIC_URL + "/assets/house_icon.svg"
				}
				alt="House Icon"
				w="65px"
				h="65px"
			/>
			<Container>
				<Heading size="md" fontWeight="bold" pb="5px">
					{isHPV
						? "Share referrals & discounts with homeowners"
						: "Share referrals with homeowners"}
				</Heading>
				<Text>
					Invite clients to have access to your skilled vendor network to stay
					top of mind.
				</Text>
			</Container>
		</Card>
	)
}
