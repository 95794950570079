import { extendTheme } from "@chakra-ui/react"
import "@fontsource-variable/inter"

const HpvTheme = extendTheme({
	fonts: {
		heading: `"Inter Variable", "sans-serif"`,
		body: `"Inter Variable", "sans-serif"`,
	},
	fontWeight: {
		light: 300,
		normal: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
	},
	colors: {
		background: "#fafafa",
		hpvPurple: "#383d93",
		hpvGreen: "#0f9f4f",
		borderColor: "#383d93",
		coolGray: {
			50: "#F9FAFB",
			100: "#F3F4F6",
			200: "#F1F1F5",
			300: "#E2E2EA",
			400: "#D5D5DC",
			500: "#B5B5BE",
			600: "#92929D",
			700: "#696974",
			800: "#4444F",
			900: "#111827",
		},
		red: {
			50: "#FDF2F2",
			100: "#FDE8E8",
			200: "#FBD5D5",
			300: "#F8B4B4",
			400: "#F98080",
			500: "#F05252",
			600: "#E02424",
			700: "#C81E1E",
			800: "#9B1C1C",
			900: "#771D1D",
		},
		blue: {
			50: "#EBF5FF",
			100: "#E1EFFE",
			200: "#C3DDFD",
			300: "#A4CAFE",
			400: "#76A9FA",
			500: "#3F83F8",
			600: "#1C64F2",
			700: "#1A56DB",
			800: "#1E429F",
			900: "#233876",
		},
	},
	components: {
		Button: {
			variants: {
				smallCta: {
					color: "white",
					fontWeight: "bold",
					bgColor: "hpvPurple",
					borderRadius: "100px",
					fontSize: "sm",
					height: "30px",
					width: "116px",
				},
			},
		},
		Heading: {
			baseStyle: {
				fontWeight: "thin",
			},
		},
		IconButton: {
			variants: {
				tooltip: {
					bgColor: "background",
					_hover: {
						bgColor: "background",
					},
					_active: {
						bgColor: "background",
					},
				},
			},
		},
		Input: {
			variants: {
				jobRequest: {
					field: {
						bgColor: "white",
						border: "1px solid #E2E2EA",
					},
				},
			},
		},
		Link: {
			baseStyle: {
				color: "blue.500",
				textDecoration: "underline",
			},
			variants: {
				secondary: {
					color: "black",
					textDecoration: "none",
					fontWeight: "semibold",
					_hover: {
						color: "hpvGreen",
						textDecoration: "none",
					},
				},
			},
		},
	},
})

export default HpvTheme
