import { useNavigate } from "react-router-dom"
import {
	Box,
	Button,
	Card,
	CardHeader,
	CardBody,
	Stack,
	StackDivider,
	Text,
} from "@chakra-ui/react"
import { dateToFormat } from "../Utilities"

export const InsightListMobileCard = ({ client, clientTag, index }) => {
	const navigate = useNavigate()

	return (
		<Card w="100%" mb="20px" key={index}>
			<CardHeader fontWeight="semibold" pb="5px">
				<Box display="flex" direction="column">
					<Stack ml="10px" gap={0}>
						<Text whiteSpace="normal" wordBreak="break-word">
							{client.name ? client.name : client.email}
						</Text>
						{client.name && (
							<Text fontSize="xs" color="grey">
								{client.email}
							</Text>
						)}
					</Stack>
				</Box>

				<Box ml="5px">{clientTag}</Box>
			</CardHeader>
			<CardBody fontSize="sm" pt="5px">
				<Stack divider={<StackDivider />} spacing="1" mt="10px">
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Invite Sent:</Text>
						<Text>
							{" "}
							{client._createdAt && dateToFormat(client._createdAt)}{" "}
						</Text>
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Number of Job Requests:</Text>
						<Text> {client.jobRequestsMade ? client.jobRequestsMade : 0}</Text>
					</Box>
				</Stack>
				{client.jobRequestsMade > 0 && (
					<Button
						onClick={() =>
							navigate(`/insights/${client.id}`, { state: { client } })
						}
						w="100%"
						mt="20px"
					>
						View
					</Button>
				)}
			</CardBody>
		</Card>
	)
}
