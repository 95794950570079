import { useState, useContext } from "react"
import { useNavigate, NavLink, useMatch } from "react-router-dom"
import {
	Box,
	Button,
	Divider,
	Flex,
	IconButton,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from "@chakra-ui/react"
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons"
import { Select } from "chakra-react-select"

import { logout } from "./firebase"
import { teamIdDefaultIds } from "./Options/DefaultTeamIds.js"
import { UserContext } from "./Contexts/UserContext"
import { useTheme } from "./Contexts/ThemeContext"
import "./select.css"

export const AgentLoggedInNav = ({ userPlus }) => {
	const { theme } = useTheme()
	const navigate = useNavigate()
	const { setUserPlus } = useContext(UserContext)
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
	const [isOpen, setIsOpen] = useState(false)

	const handleOpen = () => {
		setIsOpen(!isOpen)
	}

	const logOutFromApp = async (partner) => {
		try {
			await logout()
		} catch (error) {
			console.error("Failed to log out:", error)
		}
		partner === "HPV" ? navigate("/login/HPV") : navigate("/login")
	}

	const handleTcTeamChange = (e) => {
		setUserPlus((prevUserPlus) => ({
			...prevUserPlus,
			teamId: e.value,
		}))
		setMobileMenuOpen(false)
	}
	const team =
		(userPlus?.teamId &&
			teamIdDefaultIds.find((team) => team.teamId === userPlus.teamId)) ||
		null

	const teamSelectOptions =
		userPlus?.userType === "TC" &&
		userPlus?.tcTeams.map((team) => {
			const teamName = teamIdDefaultIds.find(
				(teamDefault) => teamDefault.teamId === team
			)
			return { value: team, label: teamName.teamName || team }
		})

	return (
		<>
			<Box
				bgColor="white"
				borderBottom={"1px solid #cccccc"}
				height="70px"
				display={["none", "none", "none", "flex"]}
			>
				<Flex height="100%" width="100%">
					{userPlus.partner && userPlus.teamId && (
						<>
							<Image
								mt="10px"
								padding="5px 0px 5px 30px"
								h="45px"
								src="./assets/home_pro_valet.svg"
							/>

							<Divider
								orientation="vertical"
								borderColor="coolGray.400"
								borderWidth={1}
								height="40px"
								ml="20px"
								mr="10px"
								alignSelf="center"
							/>
							<Image src={team.logo_path} mr="20px" />
						</>
					)}
					{userPlus.partner && !userPlus.teamId && (
						<Image
							src="/assets/home_pro_valet.svg"
							alt="logo"
							padding="18px	30px 10px 30px"
							bgColor="white"
						/>
					)}
					{(userPlus?.userType === "TC" || !userPlus?.partner) && (
						<Image
							padding="5px 30px 5px 30px"
							src={
								team
									? team.logo_path
									: process.env.PUBLIC_URL + "/assets/Logo.svg"
							}
						/>
					)}
					<Flex
						width="100%"
						height="100%"
						alignItems={"center"}
						background="white"
						gap={{ base: 3, lg: 10 }}
						lineHeight="70px"
					>
						<Text
							style={{
								borderBottom: useMatch("/vendors")
									? `2px solid	${theme.colors.borderColor}`
									: "none",
							}}
						>
							<NavLink variant="navigationNavLink" to="/vendors">
								Vendors
							</NavLink>
						</Text>
						<Text
							style={{
								borderBottom: useMatch("/job-requests")
									? `2px solid	${theme.colors.borderColor}`
									: "none",
							}}
						>
							<NavLink variant="navigationNavLink" to="/job-requests">
								Job Requests
							</NavLink>
						</Text>
						{!userPlus.partner && (
							<Text>
								<a
									href="https://quiploteams.com/how-it-works"
									target="_blank"
									rel="noopener noreferrer"
								>
									How it works
								</a>
							</Text>
						)}

						<Text
							style={{
								borderBottom: useMatch("/insights")
									? `2px solid	${theme.colors.borderColor}`
									: "none",
							}}
						>
							<NavLink variant="navigationNavLink" to="/insights">
								Insights
							</NavLink>
						</Text>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="flex-end"
						mr="30px"
						bgColor="white"
						width="1000px"
						gap={5}
					>
						{userPlus?.userType === "TC" && (
							<Box width="230px">
								<Select
									bgColor="#D9D9D9"
									borderRadius="md"
									options={teamSelectOptions}
									value={teamSelectOptions.find(
										(option) => userPlus.teamId === option.value
									)}
									onChange={(e) => handleTcTeamChange(e)}
								/>
							</Box>
						)}
						{(userPlus?.userType === "agent" || userPlus?.userType === "TC") &&
							userPlus.partner !== "HPV" && (
								<Button as={NavLink} variant="smallCta" to="/invite">
									Invite
								</Button>
							)}
						<Menu w="100%">
							<MenuButton as={Text} onClick={handleOpen} w="100px">
								Account
								<ChevronDownIcon ml="3px" />
							</MenuButton>
							<MenuList>
								{userPlus?.userType === "agent" && userPlus?.teamId === null ? (
									<MenuItem
										as="a"
										href="https://billing.stripe.com/p/login/5kA02Tg2i2QQ9rieUU"
										target="_blank"
										rel="noopener noreferrer"
										w="100%"
									>
										<Text>Billing</Text>
									</MenuItem>
								) : null}
								<MenuItem as={NavLink} to="/profile">
									Profile
								</MenuItem>
								<MenuItem>
									<Button
										onClick={() => logOutFromApp(userPlus.partner)}
										w="100%"
									>
										Log Out
									</Button>
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</Flex>
			</Box>
			<Box
				bgColor="white"
				width="100%"
				justifyContent="space-between"
				display={["flex", "flex", "flex", "none"]}
			>
				{/* Mobile */}
				{userPlus.partner && userPlus.teamId && (
					<Box w="100px" display="flex">
						<Image
							mt="5px"
							padding="5px 0px 5px 30px"
							h="45px"
							src="./assets/home_pro_valet.svg"
						/>

						<Divider
							orientation="vertical"
							borderColor="coolGray.400"
							borderWidth={1}
							height="20px"
							ml="10px"
							mr="10px"
							alignSelf="center"
						/>
						<Image src={team.logo_path} mr="20px" h="45px" mt="5px" />
					</Box>
				)}
				{userPlus.partner && !userPlus.teamId && (
					<Image
						src="/assets/home_pro_valet.svg"
						alt="logo"
						padding="18px	30px 10px 30px"
						bgColor="white"
					/>
				)}
				{(userPlus?.userType === "TC" || !userPlus.partner) && (
					<Image
						padding="5px 30px 5px 30px"
						src={
							team
								? team.logo_path
								: process.env.PUBLIC_URL + "/assets/Logo.svg"
						}
					/>
				)}
				<IconButton
					aria-label="Open Menu"
					size="xxl"
					bgColor="white"
					marginRight="18px"
					icon={<HamburgerIcon w={7} h={7} />}
					onClick={() => setMobileMenuOpen(true)}
					display={["flex", "flex", "flex", "none"]}
				/>

				<Flex
					w="100vw"
					display={isMobileMenuOpen ? "flex" : "none"}
					bgColor="gray.50"
					zIndex={20}
					h="100vh"
					pos="fixed"
					top="0"
					left="0"
					overflowY="auto"
					flexDir="column"
				>
					<Flex justify="flex-end">
						<IconButton
							mt={2}
							mr={2}
							aria-label="Open Menu"
							size="lg"
							bgColor="background"
							icon={<CloseIcon />}
							onClick={() => setMobileMenuOpen(false)}
						/>
					</Flex>
					<Flex flexDir="column" align="center">
						{userPlus?.userType === "TC" && (
							<Box maxW="300px">
								<Select
									options={teamSelectOptions}
									value={teamSelectOptions.find(
										(option) => userPlus.teamId === option.value
									)}
									onChange={(e) => handleTcTeamChange(e)}
								/>
							</Box>
						)}
						<NavLink to="/vendors" onClick={() => setMobileMenuOpen(false)}>
							<Button variant="ghost" aria-label="Vendors" my={5} w="100%">
								Vendors
							</Button>
						</NavLink>
						<NavLink
							to="/job-requests"
							onClick={() => setMobileMenuOpen(false)}
						>
							<Button variant="ghost" aria-label="Job Requests" my={5} w="100%">
								Job Requests
							</Button>
						</NavLink>
						{!userPlus.partner && (
							<Button
								as="a"
								href="https://quiploteams.com/how-it-works"
								onClick={() => setMobileMenuOpen(false)}
								target="_blank"
								rel="noopener noreferrer"
								variant="ghost"
								aria-label="How it works"
								my={5}
								w="100%"
							>
								How it works
							</Button>
						)}
						<NavLink to="/insights" onClick={() => setMobileMenuOpen(false)}>
							<Button variant="ghost" aria-label="Insights" my={5} w="100%">
								Insights
							</Button>
						</NavLink>
						{userPlus.partner !== "HPV" && (
							<NavLink to="/invite" onClick={() => setMobileMenuOpen(false)}>
								<Button variant="ghost" aria-label="Invite" my={5} w="100%">
									Invite
								</Button>
							</NavLink>
						)}

						<NavLink to="/profile" onClick={() => setMobileMenuOpen(false)}>
							<Button variant="ghost" aria-label="Profile" my={5} w="100%">
								Profile
							</Button>
						</NavLink>

						{userPlus?.userType === "agent" && userPlus?.teamId === null && (
							<Button
								as="a"
								variant="ghost"
								aria-label="Billing"
								my={5}
								w="100%"
								href="https://billing.stripe.com/p/login/5kA02Tg2i2QQ9rieUU"
								target="_blank"
								rel="noopener noreferrer"
							>
								Billing
							</Button>
						)}
						<NavLink onClick={() => logOutFromApp(userPlus.partner)}>
							<Button variant="ghost" aria-label="Log Out" my={5} w="100%">
								Log Out
							</Button>
						</NavLink>
					</Flex>
				</Flex>
			</Box>
		</>
	)
}
