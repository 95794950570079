import { Box, Heading, Image, Text } from "@chakra-ui/react"

export const HPVComingSoon = ({ heading, helperText }) => {
	return (
		<Box w="100%" m="auto" pb="40px">
			<Image src="/assets/hpv_service_icon.png" alt="coming soon" m="auto" />
			<Heading size="lg" textAlign="center">
				{heading}
			</Heading>
			<Text textAlign="center" mt="10px">
				{helperText}
			</Text>
		</Box>
	)
}
