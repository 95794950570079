import { useContext } from "react"

import { UserContext } from "../../Contexts/UserContext.js"
import AgentJobRequestListView from "./AgentJobRequestListView"
import ClientJobRequestListView from "./ClientJobRequestListView"
import { VendorJobRequestListView } from "./VendorJobRequestListView.js"

export const JobRequestListWrapper = () => {
	const { userPlus } = useContext(UserContext)

	return (
		<>
			{userPlus?.userType === "vendor" && (
				<VendorJobRequestListView userPlus={userPlus} />
			)}
			{(userPlus?.userType === "agent" || userPlus?.userType === "TC") && (
				<AgentJobRequestListView userPlus={userPlus} />
			)}
			{userPlus?.userType === "client" && (
				<ClientJobRequestListView userPlus={userPlus} />
			)}
		</>
	)
}
