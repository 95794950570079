import { Box, Text } from "@chakra-ui/react"
import { useContext } from "react"
import { UserContext } from "./Contexts/UserContext"

export const Footer = () => {
	const { userPlus } = useContext(UserContext)
	return (
		<Box
			w="100%"
			bg="white"
			color="coolGray.600"
			py={10}
			textAlign="right"
			fontSize="sm"
			pr={{ base: "10px", lg: "80px" }}
		>
			{userPlus?.partner === "HPV" ? (
				<>
					<Text>© 2024 Home Pro Valet.</Text>
					<Text>All Rights Reserved.</Text>
					<Text>For questions email info@homeprovalet.com.</Text>
				</>
			) : (
				<>
					<Text>© 2024 Relay Scheduling LLC dba Quiplo.</Text>
					<Text>All Rights Reserved.</Text>
					<Text>For questions, email contact@quiploteams.com.</Text>
				</>
			)}
		</Box>
	)
}
